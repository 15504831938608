import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Navbar from './Navbar';
import Search from './Search';
import ReviewContent from './ReviewContent';

const InputGroup = styled.div`
	margin: 0 0 64px 0;
`;

const InputHeader = styled.div`
	display: flex;
	flex-direction: row;
`;

const InputLabel = styled.h3`
	flex: 1;
	margin: 0 0 8px 0;
	font-size: 18px;
	line-height: 18px;
	color: ${(props) => (props.isInputWrong ? '#F94139' : 'black')};
`;

const InputOptional = styled.h3`
	flex: 0 auto;
	line-height: 18px;
	font-size: 14px;
	margin: 0;
	opacity: 0.33;
	text-align: right;
`;

const InputDescription = styled.p`
	margin: 0 0 8px 0;
	font-size: 16px;
	line-height: 24px;
	opacity: 0.66;
	color: ${(props) => (props.isInputWrong ? '#F94139' : 'black')};
`;

const InputBox = styled.input`
	width: 100%;
	max-width: 100%;
	height: 64px;
	margin: 8px 0 0 0;
	padding: 0 16px;
	background: #f2f2f2;
	border: 2px solid ${(props) => (props.isInputWrong ? '#F94139' : 'transparent')};
	color: ${(props) => (props.isInputWrong ? '#F94139' : 'black')};
	outline: none;
	text-align: left;
	border-radius: 4px;

	&:focus {
		border: 2px solid black;
	}
`;

const InputArea = styled.textarea`
	width: 100%;
	max-width: 100%;
	height: 176px;
	margin: 8px 0 0 0;
	padding: 16px 16px;
	background: #f2f2f2;
	border: 2px solid ${(props) => (props.isInputWrong ? '#F94139' : 'transparent')};
	color: ${(props) => (props.isInputWrong ? '#F94139' : 'black')};
	outline: none;
	text-align: left;
	border-radius: 4px;
	resize: vertical;

	&:focus {
		border: 2px solid black;
	}
`;

const InputSelect = styled.div`
	width: 100%;
	max-width: 100%;
	height: 48px;
	margin: 8px 0 0 0;
	padding: 0 16px;
	background: ${(props) => (props.active ? 'white' : '#f2f2f2')};
	border: 2px solid ${(props) => (props.active ? 'black' : 'transparent')};
	outline: none;
	text-align: left;
	border-radius: 4px;
	cursor: pointer;
`;

const InputSelectText = styled.p`
	margin: 0;
	padding: 0;
	line-height: 44px;
	color: ${(props) => (props.isInputWrong ? '#F94139' : 'black')};
`;

function FormInput(props) {
	const [inputValue, setInputValue] = React.useState('');

	const onInputChange = (e) => {
		setInputValue(e.target.value);
		props.onChange(e.target.value);
		if (props.isInputWrong) props.setIsInputWrong(false);
	};

	const validate = () => {};

	return (
		<InputGroup>
			<InputHeader>
				<InputLabel isInputWrong={props.isInputWrong}>{props.field}</InputLabel>
				<InputOptional>{props.optional && 'Optional'}</InputOptional>
			</InputHeader>
			{props.description && props.description.length > 0 && (
				<InputDescription>{props.description}</InputDescription>
			)}
			{props.inputType == 'text' && (
				<InputBox
					placeholder={props.field}
					value={inputValue}
					onChange={onInputChange}
					required={!props.optional}
				/>
			)}
			{props.inputType == 'textarea' && (
				<InputArea
					placeholder={props.field}
					value={inputValue}
					onChange={onInputChange}
					required={!props.optional}
				/>
			)}
			{props.inputType == 'select' && (
				<div>
					{props.data &&
						props.data.map((item, index) => (
							<InputSelect
								active={item == inputValue}
								onClick={() => {
									if (inputValue != item) {
										props.onChange(item);
										setInputValue(item);
										if (props.isInputWrong) props.setIsInputWrong(false);
									} else {
										props.onChange('');
										setInputValue('');
									}
								}}
							>
								<InputSelectText isInputWrong={props.isInputWrong}>{item}</InputSelectText>
							</InputSelect>
						))}
				</div>
			)}
		</InputGroup>
	);
}

export default FormInput;
