import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Navbar from './Navbar';
import Search from './Search';
import ReviewContent from './ReviewContent';
import FormInput from './FormInput';

const Container = styled.div`
	width: 100%;
	max-width: 752px;
	margin: 0 auto;
	padding: 0 16px;
`;

const Divider = styled.hr`
	width: 100%;
	height: 1px;
	margin: 16px 0;
	background: #dddddd;
	border: 0;
`;

const ReviewForm = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 720px;
	margin: 72px auto;
	padding: 0;

	@media (max-width: 719px) {
		flex-direction: column;
	}
`;

const ButtonSubmit = styled.button`
	min-width: 320px;
	max-width: 100%;
	height: 64px;
	margin-top: 16px;
	/*padding: 0 48px;*/
	background: #000;
	border: 2px solid #000;
	color: #fff;
	text-align: center;
	font-weight: bold;
	cursor: pointer;
	border-radius: 4px;

	&:hover {
		color: #000;
		background: none;
	}
`;

function WriteReviewForm(props) {
	const refFirstInput = React.useRef(null);
	const [fundName, setFundName] = React.useState('');
	const [isFundNameWrong, setIsFundNameWrong] = React.useState(false);

	const [investorName, setInvestorName] = React.useState('');
	const [isInvestorNameWrong, setIsInvestorNameWrong] = React.useState(false);

	const [reviewContent, setReviewContent] = React.useState('');
	const [isReviewContentWrong, setIsReviewContentWrong] = React.useState(false);

	const [termSheet, setTermSheet] = React.useState('');
	const [isTermSheetWrong, setIsTermSheetWrong] = React.useState(false);

	const [funded, setFunded] = React.useState('');
	const [isFundedWrong, setIsFundedWrong] = React.useState(false);

	const [rating, setRating] = React.useState('');
	const [isRatingWrong, setIsRatingWrong] = React.useState(false);

	const [interactionYear, setInteractionYear] = React.useState('');
	const [isInteractionYearWrong, setIsInteractionYearWrong] = React.useState(false);

	const [round, setRound] = React.useState('');
	const [isRoundWrong, setIsRoundWrong] = React.useState(false);
	//////////////////////////////////////////////////////////////////////////////////

	React.useEffect(() => {
		if (refFirstInput && refFirstInput.current) refFirstInput.current.focus();
	}, []);

	const submitAnother = () => {
		setFundName('');
		setInvestorName('');
		setReviewContent('');
		setTermSheet('');
		setRating('');
		setInteractionYear('');
		setRound('');

		setIsFundNameWrong(false);
		setIsInvestorNameWrong(false);
		setIsReviewContentWrong(false);
		setIsTermSheetWrong(false);
		setIsRatingWrong(false);
		setIsInteractionYearWrong(false);
		setIsRoundWrong(false);

		props.setHasSubmitted(false);
	};

	const validateForm = () => {
		let validated = true;

		if (!fundName || fundName == '') {
			alert('펀드 이름을 작성해주세요');
			setIsFundNameWrong(true);
			validated = false;
		}

		if (!reviewContent || reviewContent == '') {
			alert('리뷰 작성해주세요');
			setIsReviewContentWrong(true);
			validated = false;
		}

		if (!rating || rating == '') {
			alert('별점을 입력해주세요');
			setIsRatingWrong(true);
			validated = false;
		}

		return validated;
	};

	const onReviewSubmit = (e) => {
		console.log('let submit this review to', props.endpoint);
		e.preventDefault();

		if (validateForm()) {
			axios
				.post(props.endpoint, {
					fundName: fundName,
					investorName: investorName,
					reviewContent: reviewContent,
					termSheet: termSheet ? termSheet : 'Unknown',
					funded: funded ? funded : 'Unknown',
					rating: rating,
					interactionYear: interactionYear,
					round: round,
					accessKey: props.accessKey,
					password: props.password,
				})
				.then(function(response) {
					console.log('response', response);
					props.setHasSubmitted(true);
					console.log(props.hasSubmitted);
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function() {
					console.log('fetch complete');
					// always executed
				});
		}
	};

	return (
		<>
			{props.hasSubmitted ? (
				<>
					<ButtonSubmit onClick={submitAnother}>리뷰 더 쓰기</ButtonSubmit>
				</>
			) : (
				<ReviewForm onSubmit={onReviewSubmit}>
					<FormInput
						field="VC 회사 / 펀드 명 / 엔젤 이름"
						description=""
						inputType="text"
						onChange={(value) => {
							setFundName(value);
							if (isFundNameWrong) setIsFundNameWrong(false);
						}}
						isInputWrong={isFundNameWrong}
						setIsInputWrong={setIsFundNameWrong}
						optional={false}
					/>
					<FormInput
						field="VC 이름"
						description=""
						inputType="text"
						onChange={(value) => {
							setInvestorName(value);
							if (isInvestorNameWrong) setIsInvestorNameWrong(false);
						}}
						isInputWrong={isInvestorNameWrong}
						optional={true}
					/>
					<FormInput
						field="리뷰 작성"
						description="이 투자사(자)에 대해 다른 창업자들에게 어떤 조언을 해주고 싶으신가요?"
						inputType="textarea"
						onChange={(value) => {
							setReviewContent(value);
							if (isReviewContentWrong) setIsReviewContentWrong(false);
						}}
						isInputWrong={isReviewContentWrong}
						setIsInputWrong={setIsReviewContentWrong}
						optional={false}
					/>
					<FormInput
						field="투자 협상 여부"
						description="이 VC와 투자협상 (e.g. 텀시트 조정)을 진행하였나요? 실제 투자 여부와는 무관합니다."
						inputType="select"
						onChange={(value) => {
							setTermSheet(value);
							if (isTermSheetWrong) setIsTermSheetWrong(false);
						}}
						isInputWrong={isTermSheetWrong}
						setIsInputWrong={setIsTermSheetWrong}
						optional={true}
						data={['Yes', 'No']}
					/>
					<FormInput
						field="투자 상황"
						description="투자를 성공적으로 받으셨습니까?"
						inputType="select"
						onChange={(value) => {
							setFunded(value);
							if (isFundedWrong) setIsFundedWrong(false);
						}}
						isInputWrong={isFundedWrong}
						setIsInputWrong={setIsFundedWrong}
						optional={true}
						data={['Yes', 'No']}
					/>
					<FormInput
						field="별점"
						description="다른 창업자에게 이 VC를 추천하시겠어요? *별점은 Nugu Money 웹사이트에 공개됩니다."
						inputType="select"
						onChange={(value) => {
							setRating(value);
							if (isRatingWrong) setIsRatingWrong(false);
						}}
						isInputWrong={isRatingWrong}
						setIsInputWrong={setIsRatingWrong}
						optional={false}
						data={['1 적극 추천 반대', '2', '3', '4', '5', '6', '7', '8', '9', '10 적극 추천']}
					/>
					<FormInput
						field="리뷰 시기"
						description="이 리뷰는 언제를 기준으로 작성되었나요? (e.g. 연도)"
						inputType="text"
						onChange={(value) => {
							setInteractionYear(value);
							if (isInteractionYearWrong) setIsInteractionYearWrong(false);
						}}
						isInputWrong={isInteractionYearWrong}
						setIsInputWrong={setIsInteractionYearWrong}
						optional={true}
					/>
					<FormInput
						field="투자 라운드"
						description=""
						inputType="select"
						onChange={(value) => {
							setRound(value);
							if (isRoundWrong) setIsRoundWrong(false);
						}}
						isInputWrong={isRoundWrong}
						setIsInputWrong={setIsRoundWrong}
						optional={true}
						data={['Angel', 'Seed', 'Series A', 'Series B', 'Series C', 'Other']}
					/>
					<ButtonSubmit action="submit">Submit</ButtonSubmit>
				</ReviewForm>
			)}
		</>
	);
}

export default WriteReviewForm;
