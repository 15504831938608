import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Review = styled.div`
	margin: 40px 0 0 0;
`;

const ReviewMeta = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const ReviewMetaRating = styled.p`
	margin: 0;
	flex: 0 auto;
	opacity: 1;
	font-size: 16px;
`;

const ReviewMetaData = styled.div`
	display: flex;
	justify-content: flex-end;
	flex: 1;
	text-align: right;
`;

const ReviewMetaDataText = styled.p`
	margin: 0 0 0 16px;
	width: auto;
	margin-left: 16px;
	font-size: 14px;
	font-weight: bold;
	opacity: 1;
`;

const ReviewText = styled.p`
	margin: 8px 0 0 0;
	line-height: 28px;
	opacity: 0.8;
	white-space: pre-wrap;
`;

const FeedbackContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0 0 0;
`;

const FeedbackFeedback = styled.div`
	display: inline-block;
	width: 100%;
	padding: 8px 8px;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	background: transparent;
	color: #555555;
	border-radius: 4px;
	border: 2px solid #dddddd;
	font-weight: 600;
`;

const FeedbackButton = styled.button`
	display: inline-block;
	width: calc(50% - 8px);
	padding: 8px 8px;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	background: #dddddd;
	color: #000000;
	border-radius: 4px;
	border: none;
	font-weight: 600;
	cursor: pointer;
	outline: none;
`;

function VCReview(props) {
	// const rating = 5;
	// const funded = false;
	const {
		reviewID,
		feedbackList,
		clientIdentifier,
		hasAccess,
		accessCode,
		IP,
		rating,
		meeting,
		funded,
		review,
	} = props;
	const [gaveFeedback, setGaveFeedback] = React.useState(feedbackList.includes(reviewID));

	const [hasExpanded, setHasExpanded] = React.useState(false);

	if (!gaveFeedback && feedbackList.includes(reviewID)) {
		// alert('update later for ' + reviewID);
		setGaveFeedback(true);
	}

	const onFeedback = (value) => {
		// console.log('feedback', {
		// 	reviewID: reviewID,
		// 	IP: IP,
		// 	feedback: value,
		// 	password: accessCode,
		// 	identifier: clientIdentifier,
		// });

		axios
			.get('https://nugu-backend.vercel.app/feedback', {
				params: {
					reviewID: reviewID,
					IP: IP,
					feedback: value,
					password: accessCode,
					identifier: clientIdentifier,
				},
			})
			.then(function(response) {
				setGaveFeedback(true);
			})
			.catch(function(error) {})
			.then(function() {});
	};

	return (
		<Review>
			<ReviewMeta>
				<ReviewMetaRating>
					{rating <= 4 ? (
						<span style={{ fontWeight: 'bold', color: '#F94139' }}>{rating} </span>
					) : rating <= 6 ? (
						<span style={{ fontWeight: 'bold', color: '#E6AB3E' }}>{rating} </span>
					) : (
						<span style={{ fontWeight: 'bold', color: '#05AD44' }}>{rating} </span>
					)}
					<span style={{ lineHeight: '80%', fontSize: 14, opacity: 0.66 }}>/ 10</span>
				</ReviewMetaRating>
				<ReviewMetaData>
					{funded && <ReviewMetaDataText>투자 협상 진행</ReviewMetaDataText>}
				</ReviewMetaData>
			</ReviewMeta>
			<ReviewText>
				{review.length > 520 ? (hasExpanded ? review : review.slice(0, 520) + '...') : review}
				{review.length > 520 && <br />}
				{review.length > 520 && (
					<b style={{ cursor: 'pointer' }} onClick={() => setHasExpanded(!hasExpanded)}>
						{hasExpanded ? '접기' : '더보기'}
					</b>
				)}
			</ReviewText>
			<FeedbackContainer>
				{gaveFeedback ? (
					<FeedbackFeedback>
						감사합니다. 당신의 피드백을 통해 더욱 정확한 리뷰를 제공합니다.
					</FeedbackFeedback>
				) : (
					<>
						<FeedbackButton onClick={() => onFeedback('helpful')}>도움이 됩니다</FeedbackButton>
						<FeedbackButton onClick={() => onFeedback('unhelpful')}>
							별로 도움이 안 됩니다
						</FeedbackButton>
					</>
				)}
			</FeedbackContainer>
		</Review>
	);
}

export default VCReview;
