import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Search from './Search';
import axios from 'axios';

const Container = styled.div`
	width: 480px;
	max-width: 100%;

	margin: 0 auto 0 48px;
	padding: 48px 16px;

	@media (max-width: 879px) {
		width: 100%;
		margin: 24px auto 24px auto;
		padding: 0;
	}
`;

const Card = styled.div`
	max-width: 752px;
	width: 100%;
	padding: 16px 16px 8px 16px;
	border: 1px solid #cccccc;
`;

const Title = styled.h3`
	margin: 0 0 16px 0;
`;

const VC = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin: 0 0 8px 0;
	padding: 8px 12px;
	background: #f7f7f7;
	cursor: pointer;
	border: 2px solid transparent;

	&:hover {
		background: transparent;
		border: 2px solid black;
	}
`;

const VCName = styled.p`
	margin: 0;
	font-weight: 600;
`;

const VCRating = styled.p`
	margin: 0;
	opacity: 1;
`;

const ReviewRequest = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	margin: 0 0 8px 0;
	padding: 8px 12px;
	background: #f7f7f7;
	cursor: pointer;
	border: 2px solid transparent;

	&:hover {
		background: transparent;
		border: 2px solid black;
	}
`;

const ReviewRequestDetails = styled.p`
	margin: 4px 0;
	font-size: 13px;
	line-height: 18px;
	opacity: 0.5;
`;

function Wall(props) {
	const [wallContent, setWallContent] = React.useState(2);
	const [fundData, setFundData] = React.useState([]);

	React.useEffect(() => {
		let wallState = wallContent;
		// let wallState = Math.floor(Math.random() * 3);
		// setWallContent(wallState);

		if (wallState == 0 || wallState == 1) {
			axios
				// .post('http://localhost:8000/wall-of', {
				.post('https://nugu-backend.vercel.app/wall-of', {
					wallContent: wallState,
				})
				.then(function(response) {
					// console.log('response', response);
					console.log(response.data);
					// reviewsData = response.data;
					// console.log('reviewsData', reviewsData);
					setFundData(response.data);
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function() {});
		}
	}, []);

	let VClist = [
		{
			fundName: '빅베이신',
			ratingAverage: 1,
			fundDetails: '투자를 받았는데 지금 다소 곤란한 상황에 처해있어서 레퍼첵 필요.',
		},
		{
			fundName: '빅베이신',
			ratingAverage: 1,
			fundDetails: '투자를 받았는데 지금 다소 곤란한 상황에 처해있어서 레퍼첵 필요.',
		},
		{
			fundName: '빅베이신',
			ratingAverage: 1,
			fundDetails: '투자를 받았는데 지금 다소 곤란한 상황에 처해있어서 레퍼첵 필요.',
		},
		{
			fundName: '빅베이신',
			ratingAverage: 1,
			fundDetails: '투자를 받았는데 지금 다소 곤란한 상황에 처해있어서 레퍼첵 필요.',
		},
	];

	let reviewRequestData = [
		// {
		// 	fundName: '무신사파트너스',
		// 	details: '"1기 펀드가 엄청 잘 됐다고 들었습니다. 어떤 장단점이 있는 곳인지 궁금합니다."',
		// },
		// {
		// 	fundName: '뮤렉스파트너스',
		// 	details: '"좋은 쪽으로 유명하신 거 같은데 실제로 투자받은 분들의 후기가 궁금합니다."',
		// },
		// {
		// 	fundName: '선보엔젤파트너스',
		// 	details:
		// 		'"다른 투자자 후기 중에 선보파트너스가 악명 높다는 이야기가 언급이 되어 있는데, 구체적인 내용이 궁금합니다."',
		// },
		// { fundName: '액트너랩', details: '"업계에서 유명한 VC인듯 한데, 후기가 없어서 판단이 힘듭니다."' },
		{
			fundName: '어니스트벤처스',
			details: '"얼핏 보기에 투자 마인드가 그다지 건강해 보이지는 않는데 실제로는 어떤지 궁금합니다."',
		},
		{
			fundName: '은행권청년창업재단 디캠프',
			details:
				'"디캠프는 초기 창업자에게 입주공간과 프로그램, 투자까지 하는 기관으로 잘 알려졌는데 진짜 평판은 어떤지 사례를 듣고 싶습니다."',
		},
		{
			fundName: '인라이트벤처스',
			details: '"악평과 호평이 동시에 존재하는 파트너인지라 다른 분들의 의견이 궁금합니다."',
		},
		{
			fundName: '한국투자파트너스',
			details: '"한국에서 가장 사이즈가 큰 곳 중 하나인데 후기가 없어 궁금합니다."',
		},
	];

	return (
		<Container>
			<Card>
				<Title>
					{wallContent == 0 && 'The Wall of Shame'}
					{wallContent == 1 && 'The Wall of Fame'}
					{wallContent == 2 && '리뷰 요청이 가장 많았던 VC들'}
				</Title>
				{(wallContent == 0 || wallContent == 1) &&
					fundData.map((fund, index) => (
						<VC
							onClick={() => {
								props.setSearchInputValue(fund.fundName);
							}}
						>
							<VCName>{fund.fundName}</VCName>
							<VCRating>
								{parseInt(fund.ratingAverage) <= 4 ? (
									<span style={{ fontWeight: 'bold', color: '#F94139' }}>
										{fund.ratingAverage && fund.ratingAverage.toFixed(1)}{' '}
									</span>
								) : parseInt(fund.ratingAverage) <= 6 ? (
									<span style={{ fontWeight: 'bold', color: '#E6AB3E' }}>
										{fund.ratingAverage && fund.ratingAverage.toFixed(1)}{' '}
									</span>
								) : (
									<span style={{ fontWeight: 'bold', color: '#05AD44' }}>
										{fund.ratingAverage && fund.ratingAverage.toFixed(1)}{' '}
									</span>
								)}
								<span style={{ opacity: 0.33 }}>/ 10</span>
							</VCRating>
						</VC>
					))}
				{wallContent == 2 &&
					reviewRequestData.map((fund, index) => (
						<Link to="/write">
							<ReviewRequest>
								<VCName>{fund.fundName}</VCName>
								<ReviewRequestDetails>{fund.details}</ReviewRequestDetails>
							</ReviewRequest>
						</Link>
					))}
			</Card>
		</Container>
	);
}

export default Wall;
