import React from 'react';
import styled from 'styled-components';
import VCReview from './VCReview';

const VCFund = styled.div`
	margin: 56px 0;
	padding: 40px 32px;

	background: #f7f7f7;
	border-radius: 4px;

	@media (max-width: 719px) {
		flex-direction: column;
		justify-content: flex-start;
		margin: 64px 0;
		padding: 24px 16px;
	}
`;

const VCFundProfile = styled.div`
	margin: 0 0 28px 0;
`;

const VCFundProfileName = styled.h2`
	margin: 0 0 8px 0;
	font-size: 24px;
	line-height: 24px;
`;

const Reviews = styled.div`
	flex: 1;
`;

const RatingContainer = styled.div`
	margin: 4px 0 0 0;
	font-size: 24px;
`;

const Divider = styled.hr`
	width: 100%;
	height: 1px;
	margin: 0px 0 16px 0;
	background: #dddddd;
	border: 0;
`;

function VC(props) {
	const { fundName, rating } = props;

	// console.log(props);
	return (
		<VCFund>
			<VCFundProfile>
				<VCFundProfileName>{fundName}</VCFundProfileName>
				<span style={{ display: 'block', marginTop: 20, fontSize: 12, opacity: 0.5 }}>평균 점수</span>
				<RatingContainer>
					{parseInt(rating) <= 4 ? (
						<span style={{ fontWeight: 'bold', color: '#F94139' }}>
							{rating && rating.toFixed(1)}{' '}
						</span>
					) : parseInt(rating) <= 6 ? (
						<span style={{ fontWeight: 'bold', color: '#E6AB3E' }}>
							{rating && rating.toFixed(1)}{' '}
						</span>
					) : (
						<span style={{ fontWeight: 'bold', color: '#05AD44' }}>
							{rating && rating.toFixed(1)}{' '}
						</span>
					)}
					<span style={{ marginTop: -4, fontSize: 14 }}>/ 10</span>
				</RatingContainer>
			</VCFundProfile>
			<Divider />
			<Reviews>
				{props &&
					props.content &&
					props.content.map((review, index) => (
						<VCReview
							reviewID={review.reviewID}
							rating={review.rating}
							funded={review.funding}
							review={review.review}
							hasAccess={props.hasAccess}
							accessCode={props.accessCode}
							IP={props.IP}
							feedbackList={props.feedbackList}
							clientIdentifier={props.clientIdentifier}
						/>
					))}
			</Reviews>
		</VCFund>
	);
}

export default VC;
