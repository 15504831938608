import React from 'react';
import logo from './logo.svg';
import './normalize.min.css';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Home from './Home';
import WriteReview from './WriteReview';
// import Landing from './Landing';
// import Reviews from './Reviews';

function App() {
	return (
		<div className="App">
			<Router>
				<Switch>
					{/* <Route exact path="/"> */}
					{/* 	<header className="App-header"> */}
					{/* 		<Link to="/home"> */}
					{/* 			<img src={logo} className="App-logo" alt="logo" /> */}
					{/* 		</Link> */}
					{/* 	</header> */}
					{/* </Route> */}
					<Route exact path="/">
						{/* <Landing /> */}
						<Home />
					</Route>
					<Route exact path="/write">
						<WriteReview />
					</Route>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
