import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Navbar from './Navbar';
import Search from './Search';
import ReviewContent from './ReviewContent';
import WriteReviewForm from './WriteReviewForm';

const Container = styled.div`
	width: 100%;
	max-width: 752px;
	margin: 0 auto;
	padding: 0 16px;
`;

const Divider = styled.hr`
	width: 100%;
	height: 1px;
	margin: 16px 0;
	background: #dddddd;
	border: 0;
`;

const ButtonPrimary = styled.a`
	display: inline-block;
	width: 420px;
	max-width: 100%;
	height: 64px;
	margin: 24px 0 40px 0;
	/*padding: 12px 100px;*/

	font-size: 16px;
	line-height: 60px;

	background: black;
	border: 2px solid black;

	color: white;
	border-radius: 4px;
	text-align: center;

	&:hover {
		background: white;
		border: 2px solid black;
		color: black;
	}
`;

const PasswordForm = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 720px;
	margin: 0 auto;
	padding: 0;

	@media (max-width: 719px) {
		flex-direction: column;
	}
`;

const PasswordInput = styled.input`
	width: 100%;
	max-width: 100%;
	height: 64px;
	padding: 0 16px;
	background: #f2f2f2;
	border: 2px solid ${(props) => (props.isPasswordWrong ? '#F94139' : 'transparent')};
	color: ${(props) => (props.isPasswordWrong ? '#F94139' : 'black')};
	outline: none;
	text-align: center;
	border-radius: 4px;

	&:focus {
		border: 2px solid black;
	}
`;

const PasswordSubmit = styled.button`
	max-width: 100%;
	height: 64px;
	margin-top: 16px;
	/*padding: 0 48px;*/
	background: #000;
	border: 2px solid #000;
	color: #fff;
	text-align: center;
	font-weight: bold;
	cursor: pointer;
	border-radius: 4px;

	&:hover {
		color: #000;
		background: none;
	}
`;

function WriteReview(props) {
	const [reviewsData, setReviewsData] = React.useState([]);
	const [searchInputValue, setSearchInputValue] = React.useState('');

	const [password, setPassword] = React.useState('');
	const [isPasswordWrong, setIsPasswordWrong] = React.useState(false);

	const [accessKey, setAccessKey] = React.useState('');
	const [endpoint, setEndpoint] = React.useState('');
	const [writeEndpoint, setWriteEndpoint] = React.useState('');

	const [hasSubmitted, setHasSubmitted] = React.useState(false);

	const refPasswordInput = React.useRef(null);

	React.useEffect(() => {
		if (refPasswordInput && refPasswordInput.current) refPasswordInput.current.focus();

		let accessCode = getCookie('super-secret-access-code');
		setPassword(accessCode);
	}, []);

	const onSearchChange = (e) => {
		let searchValue = e.target.value;
		console.log('searching for..', searchValue);
		setSearchInputValue(searchValue);
		// setSearchInputValue(value);
	};

	const onPasswordSubmit = (e) => {
		e.preventDefault();

		axios
			// .post('http://localhost:8000/write', {
			.post('https://nugu-backend.vercel.app/write', {
				password: password,
			})
			.then(function(response) {
				// console.log('Response is', response.data, response.data.accessKey, response.data.endpoint);
				if (response && response.data.accessKey && response.data.endpoint) {
					// console.log('success', response.data.accessKey, response.data.endpoint);
					setAccessKey(response.data.accessKey);
					setEndpoint(response.data.endpoint);
					setCookie('super-secret-access-code', password, 28);
				} else {
					setAccessKey('');
					setEndpoint('');
					setIsPasswordWrong(true);
					eraseCookie('super-secret-access-code');
					// console.log('error');
				}
			})
			.catch(function(error) {
				console.log(error);
				if (error.toString().indexOf('401') != -1) {
					setAccessKey('');
					setEndpoint('');
					setIsPasswordWrong(true);
					eraseCookie('super-secret-access-code');
				}
			})
			.then(function() {
				// console.log('fetch complete');
				// always executed
			});
	};

	const setCookie = (name, value, days) => {
		var expires = '';
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		}
		document.cookie = name + '=' + (value || '') + expires + '; path=/';
	};

	const getCookie = (name) => {
		var nameEQ = name + '=';
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	};

	const eraseCookie = (name) => {
		document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	};

	return (
		<Container>
			<Navbar />
			{accessKey && accessKey.length > 0 ? (
				<>
					<section>
						<div className="title">
							{hasSubmitted ? (
								<h1>리뷰 제출해주셔서 감사합니다</h1>
							) : (
								<>
									<h1>VC 리뷰 제출</h1>
									<p className="public">모든 리뷰는 익명으로 게시됩니다.</p>
									<p className="public">
										리뷰는 최대한 솔직하고 구체적으로 기입해 주세요. 당신이 남기는 리뷰는
										스타트업 업계 내 창업자와 VC간정보의 비대칭의 해소에 큰 도움이 됩니다.
									</p>
								</>
							)}
						</div>
					</section>
					{!hasSubmitted && <Divider />}
					<WriteReviewForm
						accessKey={accessKey}
						endpoint={endpoint}
						password={password}
						hasSubmitted={hasSubmitted}
						setHasSubmitted={setHasSubmitted}
					/>
					{hasSubmitted && (
						<p>
							아니면 <Link to="/">리뷰 보러가기</Link>
						</p>
					)}
				</>
			) : (
				<>
					<section>
						<div className="title">
							<h1>리뷰를 작성하시기 전에 신분확인이 필요합니다</h1>
						</div>
						<p className="public">
							입력하신 정보는 신분을 확인하는 목적으로만 쓰이며, 그 외의 용도로 사용되지
							않습니다. 취급하는 모든 개인정보는 익명으로 관리되며, 개인정보 보호법 등 관련
							법령상 개인정보보호 규정을 준수하여 처리됩니다.
						</p>
						<ButtonPrimary href="https://airtable.com/shrrBCPFKt0IzH065" target="_blank">
							누구머니 창업자 리스트에 등록 신청
						</ButtonPrimary>
					</section>
					<Divider />
					<div className="title">
						<h2>이미 확인 되셨나요?</h2>
						<PasswordForm onSubmit={onPasswordSubmit}>
							<PasswordInput
								ref={refPasswordInput}
								placeholder="누구머니 비밀번호"
								value={password}
								onChange={(e) => {
									setIsPasswordWrong(false);
									setPassword(e.target.value);
								}}
								isPasswordWrong={isPasswordWrong}
							/>
							<PasswordSubmit>로그인 하기</PasswordSubmit>
							{isPasswordWrong && (
								<p style={{ marginTop: 24 }}>
									비밀번호가 틀렸습니다. 엑세스가 있으시나 비밀번호를 잃으셨으면{' '}
									<a href="mailto:founder@nugu.money">founder@nugu.money</a>에 이메일을
									보내주세요.
								</p>
							)}
						</PasswordForm>
					</div>
				</>
			)}
		</Container>
	);
}

export default WriteReview;
