import React from 'react';
import styled from 'styled-components';
import Navbar from './Navbar';
import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import VC from './VC';
import VCReview from './VCReview';

function Reviews(props) {
	const [hasAccess, setHasAccess] = React.useState(false);
	const [accessCode, setAccessCode] = React.useState(false);
	const [reviewsData, setReviewsData] = React.useState([]);
	const [IP, setIP] = React.useState('');
	const [clientIdentifier, setClientIdentifier] = React.useState('');
	const [feedbackList, setFeedbackList] = React.useState([]);

	React.useEffect(() => {
		let accessCode = getCookie('super-secret-access-code');
		if (accessCode) {
			setHasAccess(true);
			setAccessCode(accessCode);
		}

		getReviewData();
		getIP();

		(async () => {
			// We recommend to call `load` at application startup.
			const fp = await FingerprintJS.load();

			// The FingerprintJS agent is ready.
			// Get a visitor identifier when you'd like to.
			const result = await fp.get();

			// This is the visitor identifier:
			const visitorId = result.visitorId;
			// console.log(visitorId);
			setClientIdentifier(visitorId);
			getFeedbackList(visitorId);
		})();
	}, []);

	// React.useEffect(() => {
	// 	// fund && fund.fundName.includes(searchInputValue)
	// 	reviewsData.filter((fund) => {
	// 		console.log(
	// 			fund.fundName,
	// 			props.searchInputValue,
	// 			fund.fundName.includes(props.searchInputValue)
	// 		);
	// 	});
	// 	console.log('reviewcontent refresh on');
	// });

	const getReviewData = () => {
		// console.log('get review data', password);
		axios
			// .post('http://localhost:8000/reviews', {
			.get('https://nugu-backend.vercel.app/reviews', {})
			.then(function(response) {
				setReviewsData(response.data);
			})
			.catch(function(error) {})
			.then(function() {});
	};

	const getIP = () => {
		axios
			// .post('http://localhost:8000/reviews', {
			.get('https://nugu-backend.vercel.app/ip', {})
			.then(function(response) {
				setIP(response.data);
			})
			.catch(function(error) {})
			.then(function() {});
	};

	const getFeedbackList = (identifier) => {
		axios
			// .post('http://localhost:8000/reviews', {
			.get('https://nugu-backend.vercel.app/feedback-list', {
				params: { clientIdentifier: identifier },
			})
			.then(function(response) {
				let data = response.data;
				let feedbackListTemp = [];

				for (let i = 0; i < data.length; i++) {
					feedbackListTemp.push(data[i].reviewID);
				}

				setFeedbackList(feedbackListTemp);
				// console.log('feedback list', feedbackListTemp);
			})
			.catch(function(error) {})
			.then(function() {});

		// 		if (IP && reviewsData.length > 0) {
		// 			let feedbackListBuilder = [];
		//
		// 			for (let i = 0; i < )
		// 		}
	};

	const getCookie = (name) => {
		var nameEQ = name + '=';
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	};

	return (
		<section>
			{reviewsData && reviewsData.length > 0 ? (
				reviewsData
					.filter((fund) => fund && fund.fundName && fund.fundName.includes(props.searchInputValue))
					.map((VCFund, index) => (
						<VC
							key={`vc-fund--${index}`}
							fundName={VCFund.fundName}
							rating={VCFund.ratingAverage}
							content={VCFund.content}
							hasAccess={hasAccess}
							accessCode={accessCode}
							IP={IP}
							feedbackList={feedbackList}
							clientIdentifier={clientIdentifier}
						/>
					))
			) : (
				<p style={{ textAlign: 'center' }}>로딩 중..</p>
			)}
			<p style={{ textAlign: 'center', margin: '48px auto 56px auto' }}>
				찾고 계신 투자자가 없나요?{' '}
				<a href="https://airtable.com/shr0TGvS1Aqbcipxz" target="_blank">
					투자자 리뷰 요청하기
				</a>
			</p>
		</section>
	);
}

export default Reviews;
