import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Navbar from "./Navbar";
import Loader from "./Loader";
import Wall from "./Wall";
import Search from "./Search";
import ReviewContent from "./ReviewContent";

const Wrapper = styled.div`
	width: 100%;
	margin: 0;
	padding: 0 16px;
`;

const Container = styled.div`
	width: 100%;
	max-width: 752px;
	margin: 0 auto;
	padding: 0;
`;

const ContainerWide = styled.div`
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
	padding: 0 16px;
	display: flex;
	flex-direction: row;
	/*justify-content: center;*/

	/*@media (max-width: 719px) {*/
	@media (max-width: 879px) {
		flex-direction: column;
	}
`;

const HeaderContent = styled.div`
	position: relative;
	z-index: 99999999;
	
	flex: 0 752px;
	margin: 144px auto;
	align-items: center;
	justify-content: center;

/*	outline: 1px solid red;*/

/*	animation: App-logo-spin infinite 2.5s linear;*/

	transform-origin: 50% 50%;

	/*@media (max-width: 719px) {*/
	@media (max-width: 879px) {
		flex: 0 auto;
	}
`;

const HeaderTable = styled.div`
	flex: 0 auto;
`;

const Divider = styled.hr`
	width: 100%;
	height: 1px;
	margin: 16px 0;
	background: #dddddd;
	border: 0;
`;

const ButtonPrimary = styled.a`
	display: block;
	width: 420px;
	max-width: 100%;
	height: 64px;
	margin: 24px auto 40px auto;
	/*padding: 12px 100px;*/

	font-size: 16px;
	line-height: 60px;

	background: black;
	border: 2px solid black;

	color: white;
	border-radius: 4px;
	text-align: center;

	// animation: App-Colorful infinite 1.5s linear;

	&:hover {
		background: white;
		border: 2px solid black;
		color: black;
	}

	span {
		font-weight: bold;
	}
`;

const Banner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 72px;
	text-align: center;
	background: #000;
	color: rgba(255, 255, 255, 0.75);
	font-weight: 600;
	font-size: 18px;
`;

const Background = styled.div`
	position: fixed;
	z-index: -33333333;
	width: 100vw;
	height: 100vh;
/*	animation: App-Colorful infinite 15s linear;*/
/*	background: #ff0000;*/
/*	background: #ffdfdf;*/
	background: #ffffff;
/*	opacity: 0.1;*/
`;

const IMGBackground = styled.img`
	position: fixed;
	z-index: -33333333;
	left: 0;
	top: 0;
	min-width: 100vw;
	min-height: 100vh;
	opacity: 0.5;
`;

function Home(props) {
	const [reviewsData, setReviewsData] = React.useState([]);
	const [searchInputValue, setSearchInputValue] = React.useState("");

	const onSearchChange = (e) => {
		let searchValue = e.target.value;
		console.log("searching for..", searchValue);
		setSearchInputValue(searchValue);
		// setSearchInputValue(value);
	};

	return (
		<>
			<Background />
			{/* <IMGBackground src="https://wallpaper.dog/large/17169278.jpg" /> */}
			<Wrapper>
				{/* <Loader /> */}
				<Container>
					<Navbar />
				</Container>
				<Container>
					<HeaderContent>
						<Link to="/write">
							<ButtonPrimary>
								<p className="public" style={{ textAlign: "center", opacity: 1 }}>
									<span>투자자 후기 쓰기</span>
								</p>
							</ButtonPrimary>
						</Link>
					</HeaderContent>
					{/* <HeaderTable> */}
					{/* 	<Wall setSearchInputValue={setSearchInputValue} /> */}
					{/* </HeaderTable> */}
				</Container>
				<Container>
					<Container>
						<Search searchInputValue={searchInputValue} onSearchChange={onSearchChange} />
						<Divider />
						<ReviewContent searchInputValue={searchInputValue} />
					</Container>
				</Container>
			</Wrapper>
		</>
	);
}

export default Home;
